<!--门禁模块--室内人员显示-->
<template>
  <div
    id="indoorPersonne"
    v-loading="loading"
    :element-loading-text="$t('indoorPersonne.a1')"
  >
    <div class="header">
      <my-headertitle>{{ $t("indoorPersonne.a2") }}</my-headertitle>
      <div class="header-inquire">
        <a-row type="flex" align="bottom">
          <a-col :span="4">
            <p>{{ $t("indoorPersonne.a3") }}</p>
            <a-cascader
              style="width: 250px"
              :placeholder="$t('indoorPersonne.a40')"
              :options="options"
              change-on-select
              v-model="select"
              :allowClear="false"
              :field-names="{
                label: 'title',
                value: 'key',
                children: 'children',
              }"
            />
          </a-col>
          <a-col :span="18"> </a-col>
          <a-col :span="2">
            <a-button type="primary" style="width: 100%" @click="inquire">
              {{ $t("indoorPersonne.a4") }}
            </a-button>
          </a-col>
        </a-row>
      </div>
    </div>
    <div class="main" ref="main" v-table-size="tableSize">
      <my-tabletitle>
        {{ $t("indoorPersonne.a5") }}
        <template v-slot:name>
          <a-popover placement="bottomRight">
            <template slot="content">
              <a-row style="width: 350px">
                <a-col
                  v-for="(item, index) in displayContent"
                  :key="index"
                  :span="12"
                >
                  <a-checkbox :disabled="item.disabled" v-model="item.select">
                    {{ item.title }}
                  </a-checkbox>
                </a-col>
              </a-row>
            </template>
            <template slot="title">
              <span>{{ $t("alarm.a50") }}</span>
            </template>
            <a-button type="primary" size="small">
              {{ $t("alarm.a50") }}
            </a-button>
          </a-popover>
        </template>
      </my-tabletitle>
      <a-table
        :columns="column"
        :dataSource="tableDate"
        :scroll="size"
        :pagination="pagination"
        :rowKey="(record) => record.acsId"
      >
        <template slot="chaozuo" slot-scope="text, record">
          <a-button type="primary" @click="() => reset(record)">{{
            $t("indoorPersonne.a6")
          }}</a-button>
        </template>
      </a-table>
    </div>
    <div class="footer">
      <a-button type="primary" :disabled="disabled" @click="indoorSet">
        {{ $t("indoorPersonne.a7") }}
      </a-button>
    </div>
    <!-- 室内人员设置对话框 -->
    <a-modal
      :title="$t('indoorPersonne.a7')"
      centered
      :width="1100"
      :visible="visible"
      :getContainer="() => this.$refs.main"
      :destroyOnClose="true"
      @cancel="personCancel"
      v-drag
    >
      <template slot="footer">
        <a-button key="back" @click="personCancel" :disabled="disableds">
          {{ $t("indoorPersonne.a9") }}
        </a-button>
        <a-button
          key="submit"
          type="primary"
          @click="personOk"
          :disabled="disableds"
        >
          {{ $t("indoorPersonne.a8") }}
        </a-button>
      </template>
      <div
        class="modal"
        v-loading="personloading"
        :element-loading-text="$t('indoorPersonne.a1')"
      >
        <div class="modal_header">
          <a-row :gutter="16" type="flex" align="bottom">
            <a-col :span="4">
              <p>{{ $t("indoorPersonne.a10") }}</p>
              <a-input
                v-model="cardAttachNo"
                allow-clear
                :placeholder="$t('indoorPersonne.a11')"
                style="width: 100%"
              />
            </a-col>
            <a-col :span="4">
              <p>{{ $t("indoorPersonne.a12") }}</p>
              <a-input
                v-model="kanriCode"
                allow-clear
                :placeholder="$t('indoorPersonne.a13')"
                style="width: 100%"
              />
            </a-col>
            <a-col :span="13"> </a-col>
            <a-col :span="3">
              <a-button type="primary" style="width: 100%" @click="Setinquire">
                {{ $t("indoorPersonne.a14") }}
              </a-button>
            </a-col>
          </a-row>
        </div>
        <div class="modal_main">
          <my-tabletitle>{{ $t("indoorPersonne.a15") }}</my-tabletitle>
          <a-table
            :columns="ownerColumns"
            :dataSource="allPersonTableList"
            :scroll="{ y: 240 }"
            :pagination="pagination"
            :rowKey="(record) => record.acsId"
          >
            <template slot="chaozuo" slot-scope="text, record">
              <a-button type="primary" @click="() => personAdd(record)"
                >{{ $t("indoorPersonne.a16") }} ↓</a-button
              >
            </template>
          </a-table>
          <a-divider />
          <my-tabletitle>{{ $t("indoorPersonne.a17") }}</my-tabletitle>
          <a-table
            :columns="ownerColumns"
            :dataSource="ownerList"
            :scroll="{ y: 240 }"
            :pagination="pagination"
            :rowKey="(record) => record.acsId"
          >
            <template slot="chaozuo" slot-scope="text, record">
              <a-button type="primary" @click="() => personDelete(record)"
                >{{ $t("indoorPersonne.a18") }} ↑</a-button
              >
            </template>
          </a-table>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import tabletitle from "../../components/Title/tabletitle";
import headerTitle from "../../components/Title/headerTitle";
import {
  doorTimetableListKT,
  stayPersonInfo,
  PersonReset,
  allStayPersonInfo,
  indoorPersonSet,
} from "../../api/door";
import {
  tableSort,
  tableTitleDisplay,
  tableIndexDisplay,
} from "../../utils/utils";

export default {
  name: "indoorPersonne",
  data() {
    return {
      loading: false,
      visible: false,
      personloading: false,
      size: { x: 1500, y: 240 },
      tableDate: [],
      allPersonTableList: [],
      options: [],
      select: [],
      ownerList: [],
      kanriCode: "",
      cardAttachNo: "",
      disableds: false,
      pagination: {
        total: 0, //数据总数
        pageSize: 40, //每页中显示10条数据
        showTotal: (total) => ` ${total} ${this.$t("indoorPersonne.a19")}`, //分页中显示总的数据
        showQuickJumper: true, //是否可以快速跳转至某页
        defaultCurrent: 1, //默认当前页面数
        hideOnSinglePage: false, //只有一页时是否隐藏分页：默认值false
        onShowSizeChange: (current, pageSize) => {
          this.pagination.defaultCurrent = 1;
          this.pagination.pageSize = pageSize;
        },
        // 改变每页数量时更新显示
        onChange: (current, size) => {
          this.pagination.defaultCurrent = current;
          this.pagination.pageSize = size;
        },
      },
      columns: [
        {
          id: "1",
          title: "NO",
          dataIndex: "NO",
          customRender: (text, record, index) =>
            `${
              (this.pagination.defaultCurrent - 1) * this.pagination.pageSize +
              index +
              1
            }`,
          align: "center",
          width: 60,
        },
        {
          id: "2",
          title: this.$t("indoorPersonne.a20"),
          dataIndex: "areaName",
          sorter: (a, b) => {
            return tableSort(a, b, "areaName");
          },
          ellipsis: true,
        },
        {
          id: "3",
          title: this.$t("indoorPersonne.a21"),
          dataIndex: "name",
          sorter: (a, b) => {
            return tableSort(a, b, "name");
          },
        },
        {
          id: "4",
          title: this.$t("indoorPersonne.a22"),
          dataIndex: "department",
          sorter: (a, b) => {
            return tableSort(a, b, "department");
          },
        },
        {
          id: "5",
          title: this.$t("indoorPersonne.a23"),
          dataIndex: "stayOrOver",
          sorter: (a, b) => {
            return tableSort(a, b, "stayOrOver");
          },
        },
        {
          id: "6",
          title: this.$t("indoorPersonne.a24"),
          dataIndex: "enterExitDateTime",
          sorter: (a, b) => {
            return tableSort(a, b, "enterExitDateTime");
          },
          width: 160,
        },
        {
          id: "7",
          title: this.$t("indoorPersonne.a25"),
          dataIndex: "syozoku1",
          sorter: (a, b) => {
            return tableSort(a, b, "syozoku1");
          },
        },
        {
          id: "8",
          title: this.$t("indoorPersonne.a26"),
          dataIndex: "groupName",
          sorter: (a, b) => {
            return tableSort(a, b, "groupName");
          },
        },
        {
          id: "9",
          title: this.$t("indoorPersonne.a27"),
          dataIndex: "cardAttachNo",
          sorter: (a, b) => {
            return tableSort(a, b, "cardAttachNo");
          },
        },
        {
          id: "10",
          title: this.$t("indoorPersonne.a28"),
          dataIndex: "syozoku2",
          sorter: (a, b) => {
            return tableSort(a, b, "syozoku2");
          },
        },
        {
          id: "11",
          title: this.$t("indoorPersonne.a29"),
          dataIndex: "syozoku3",
          sorter: (a, b) => {
            return tableSort(a, b, "syozoku3");
          },
        },
        {
          id: "12",
          title: this.$t("indoorPersonne.a30"),
          dataIndex: "syozoku4",
          sorter: (a, b) => {
            return tableSort(a, b, "syozoku4");
          },
        },
        {
          id: "13",
          title: this.$t("indoorPersonne.a31"),
          dataIndex: "syozoku5",
          sorter: (a, b) => {
            return tableSort(a, b, "syozoku5");
          },
        },
        {
          id: "14",
          title: this.$t("indoorPersonne.a32"),
          dataIndex: "chaozuo",
          scopedSlots: { customRender: "chaozuo" },
          width: 100,
          fixed: "right",
        },
      ],
      column: [],
      displayContent:
        JSON.parse(localStorage.getItem("indoorPersonne_tableTitle")) || [],
      ownerColumns: [
        {
          title: "NO",
          customRender: (text, record, index) =>
            `${
              (this.pagination.defaultCurrent - 1) * this.pagination.pageSize +
              index +
              1
            }`,
          width: 60,
        },
        {
          title: this.$t("indoorPersonne.a33"),
          dataIndex: "cardAttachNo",
          ellipsis: true,
        },
        {
          title: this.$t("indoorPersonne.a34"),
          dataIndex: "kanriCode",
          ellipsis: true,
        },
        {
          title: this.$t("indoorPersonne.a35"),
          dataIndex: "name",
          ellipsis: true,
        },
        {
          title: this.$t("indoorPersonne.a36"),
          dataIndex: "syozoku1",
          ellipsis: true,
        },
        {
          title: this.$t("indoorPersonne.a37"),
          dataIndex: "chaozuo",
          scopedSlots: { customRender: "chaozuo" },
          width: 100,
        },
      ],
    };
  },
  computed: {
    disabled() {
      const length = this.select.length;
      if (length > 2) {
        return false;
      } else {
        return true;
      }
    },
  },
  watch: {
    // 监听表格表头显示内容生成表格表头
    displayContent: {
      handler(newValue) {
        const columns = this.columns;
        const name = "indoorPersonne_tableTitle";
        const data = tableIndexDisplay(name, columns, newValue);
        this.column = data;
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.areaListGet();
    // 表格表头
    const name = "indoorPersonne_tableTitle";
    const columns = this.columns;
    const data = tableTitleDisplay(name, columns);
    if (data.columns == undefined) {
      this.displayContent = data.displayContent;
    } else {
      this.column = data.columns;
      this.displayContent = data.displayContent;
    }
  },
  methods: {
    // 表格高度计算
    tableSize() {
      const indexDom = document.getElementById("indoorPersonne");
      if (indexDom !== null) {
        const height = indexDom.getElementsByClassName("main")[0].clientHeight;
        const title = indexDom.getElementsByClassName("ant-table-thead")[0].clientHeight;
        const tableHeight = height - 118.5 - title;
        this.size.y = tableHeight;
      }
    },
    // 区域列表获取
    areaListGet() {
      this.loading = true;
      const data = {
        siteId: this.$route.query.id,
        schType: "14",
      };
      doorTimetableListKT(data)
        .then((res) => {
          this.loading = false;
          const { data } = res;
          if (data.key !== "0") {
            this.options.push(data);
            this.select.push(data.key);
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    // 室内人员列表的查询方法
    inquire() {
      this.loading = true;
      const number = this.select.length - 1;
      const areaId = this.select[number].split(",")[1];
      const data = {
        siteId: this.$route.query.id,
        areaId,
        mode: number,
        refresh: true,
        sortOrders: true,
      };
      stayPersonInfo(data)
        .then((res) => {
          this.loading = false;
          const { data } = res;
          this.tableDate = data;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    // 室内人员列表的重置方法
    reset(value) {
      this.$confirm({
        title: `${this.$t("indoorPersonne.a38")} ${value.name} ${this.$t(
          "indoorPersonne.a39"
        )}`,
        centered: true,
        onOk: () => {
          this.loading = true;
          let date = value.enterExitDateTime;
          let timestamp = 0;
          if (date !== null) {
            date = date.substring(0, 19);
            date = date.replace(/-/g, "/");
            timestamp = new Date(date).getTime();
          }
          const data = {
            acsIdList: [value.acsId],
            cardInfoList: [
              {
                acsId: value.acsId,
                acsTime: timestamp,
                cardId: value.cardAttachNo,
              },
            ],
          };
          PersonReset(data)
            .then((res) => {
              this.loading = false;
              const { errorCode, msg } = res;
              if (errorCode == "00") {
                this.$message.success(msg);
              } else {
                this.$message.error(msg);
              }
            })
            .catch((err) => {
              console.log(err);
              this.loading = false;
            });
        },
      });
    },
    // 室内人员设置的查询方法
    async inquirePersonSet() {
      this.personloading = true;
      const number = this.select.length - 1;
      const areaId = this.select[number].split(",")[1];
      const data = {
        siteId: this.$route.query.id,
        areaId,
        cardAttachNo: this.cardAttachNo,
        kanriCode: this.kanriCode,
      };
      await allStayPersonInfo(data)
        .then((res) => {
          const { data } = res;
          this.allPersonTableList = data;
        })
        .catch((err) => {
          console.log(err);
        });
      const date = {
        siteId: this.$route.query.id,
        areaId,
        mode: number,
        refresh: true,
        sortOrders: true,
      };
      await stayPersonInfo(date)
        .then((res) => {
          const { data } = res;
          this.ownerList = data;
        })
        .catch((err) => {
          console.log(err);
        });
      for (let i = 0; i < this.ownerList.length; i++) {
        let id = this.ownerList[i].acsId;
        let list = this.allPersonTableList;
        let result = list.findIndex((row) => row.acsId === id);
        list.splice(result, 1);
      }
      this.personloading = false;
    },
    // 室内人员设--室内人员设置按钮
    indoorSet() {
      this.visible = true;
      this.inquirePersonSet();
    },
    // 室内人员设置的查询按钮
    Setinquire() {
      this.inquirePersonSet();
    },
    //室内人员设置关闭按钮
    personCancel() {
      this.visible = false;
    },
    // 室内人员设置设置按钮
    personOk() {
      this.personloading = true;
      this.disableds = true;
      const number = this.select.length - 1;
      const areaId = this.select[number].split(",")[1];
      const cardIdList = [];
      for (let i = 0; i < this.ownerList.length; i++) {
        cardIdList.push(this.ownerList[i].acsId);
      }
      const data = {
        areaId,
        cardIdList,
      };
      indoorPersonSet(data)
        .then((res) => {
          this.personloading = false;
          this.disableds = false;
          const { errorCode, msg } = res;
          if (errorCode == "00") {
            this.$message.success(msg);
            this.visible = false;
            this.inquire();
          } else {
            this.$message.error(msg);
          }
        })
        .catch((err) => {
          // console.log(err);
          this.personloading = false;
          this.disableds = false;
        });
    },
    // 室内人员--添加按钮
    personAdd(value) {
      const id = value.acsId;
      let list = this.allPersonTableList;
      this.ownerList.push(value);
      let result = list.findIndex((row) => row.acsId === id);
      list.splice(result, 1);
    },
    // 室内人员--删除按钮
    personDelete(value) {
      const id = value.acsId;
      let list = this.ownerList;
      this.allPersonTableList.unshift(value);
      let result = list.findIndex((row) => row.acsId === id);
      list.splice(result, 1);
    },
  },
  components: {
    "my-tabletitle": tabletitle,
    "my-headertitle": headerTitle,
  },
};
</script>

<style scoped>
div /deep/.ant-modal-header {
  background-color: #7682ce;
}
div /deep/.ant-modal-body {
  padding: 0px;
}
#indoorPersonne {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.header {
  min-width: 850px;
  height: 140px;
  padding: 0 20px;
  overflow: hidden;
}
p {
  font-size: 14px;
  margin-bottom: 5px;
  color: #000000a6;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.header-inquire {
  height: 89px;
  padding: 15px 0px;
  overflow: hidden;
  border-bottom: 1px solid rgba(12, 12, 12, 0.1);
}
.main {
  height: calc(100% - 192px);
  width: 100%;
  min-width: 670px;
  padding: 0px 20px;
  overflow: hidden;
}
.footer {
  display: flex;
  justify-content: flex-end;
  height: 52px;
  padding: 10px 20px;
  box-shadow: 0px 0px 10px #bdbcbc;
}
.modal {
  height: 500px;
  overflow: hidden;
}
.modal_header {
  width: 100%;
  padding: 20px 20px;
  border-bottom: 1px solid rgba(12, 12, 12, 0.1);
}
.modal_main {
  height: calc(100% - 99px);
  padding: 20px 20px 0px 20px;
  overflow: auto;
}
</style>